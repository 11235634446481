
import { defineComponent, ref, onMounted } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"
import type { ElForm } from "element-plus"
import { Plus, Delete } from "@element-plus/icons-vue"
import type { ElUpload, ElInput } from "element-plus"
import { Product, ProductGift } from "@/store/modules/ProductModule"
import { BoxType } from "@/store/modules/BoxInfoModule"
import NewBoxItemInfoModal from "@/components/modals/forms/NewBoxItemInfoModal.vue"
import * as _ from 'lodash'

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  components: {
    Plus, 
    Delete,
    NewBoxItemInfoModal
  },
  setup() {
    onMounted(() => {
      if (!route.params.id) {
        setCurrentPageBreadcrumbs("商品新增", ["商品管理;products"])
      } else {
        setCurrentPageBreadcrumbs("商品编辑", ["商品管理;products", route.params.id.toString()])
      }
    });

    const rules = ref({
      // name: [
      //   {
      //     required: true,
      //     message: "商圈名称不可为空",
      //     trigger: "change",
      //   },
      // ],
    });

    const formRef = ref<FormInstance>()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const product = ref({} as Product)

    const onCancel = () => {
      router.push({ name: "products" });
    };

    const isNew = !route.params.id;

    const onSubmit = (formEl: FormInstance | undefined) => {
      if (!formEl) return;

      formEl.validate((valid) => {
        if (valid) {
          product.value.product_images_attributes = submitFileList

          if (isNew) {
            store
              .dispatch(
                Actions.CREATE_PRODUCT,
                product.value
              )
              .then(() => {
                router.push({ name: "products" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          } else {
            store
              .dispatch(Actions.UPDATE_PRODUCT, {
                id: route.params.id,
                values: product.value,
              })
              .then(() => {
                router.push({ name: "products" });
              })
              .catch(() => {
                // const [error] = Object.keys(store.getters.getErrors);
                // Swal.fire({
                //   text: store.getters.getErrors[error],
                //   icon: "error",
                //   buttonsStyling: false,
                //   confirmButtonText: "Try again!",
                //   customClass: {
                //     confirmButton: "btn fw-bold btn-light-danger",
                //   },
                // });
              });
          }
        } else {
          return false;
        }
      });
    };

    if (!isNew) {
      store
        .dispatch(Actions.GET_PRODUCT, route.params.id)
        .then(() => {
          product.value = store.getters.currentProduct

          imageUrl.value = product.value.image_url.startsWith("http")
            ? product.value.image_url
            : ""

          product.value.product_images_attributes.forEach(function (i) {
            fileList.value.push({
              id: i.id,
              url: i.image.url,
            })
          })
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    } else {
      product.value.product_gifts_attributes = []
      product.value.product_type = 'normal'
    }

    const imageUrl = ref("")

    const onChange = (file) => {
      var reader = new FileReader();
      reader.readAsDataURL(file.raw)
      reader.onload = (e) => {
        imageUrl.value = e.target?.result?.toString() || ""
        product.value.image = imageUrl.value
      }
    }

    const product_brand_options = ref(Array<BoxType>())

    store
      .dispatch(Actions.GET_PRODUCT_BRAND_OPTIONS)
      .then(() => {
        product_brand_options.value = product_brand_options.value.concat(store.getters.currentProductBrandOptions)
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const product_recycle_type_options = ref([] as any)

    store
      .dispatch(Actions.GET_PRODUCT_RECYCLE_TYPE_OPTIONS)
      .then(() => {
        product_recycle_type_options.value = product_recycle_type_options.value.concat(store.getters.currentProductRecycleTypeOptions)
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const product_ip_options = ref([] as any)

    store
      .dispatch(Actions.GET_PRODUCT_IP_OPTIONS)
      .then(() => {
        product_ip_options.value = product_ip_options.value.concat(store.getters.currentProductIpOptions)
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const event_options = ref([] as any)

    store
      .dispatch(Actions.GET_EVENTS)
      .then(() => {
        event_options.value = [{id: '', name: ''} as any]
        event_options.value = event_options.value.concat(store.getters.currentEvents)
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // });
      })

    const fileList = ref([] as any);
    const submitFileList = [] as any;

    const imageListOnChange = (file, fileList) => {
      var reader = new FileReader();
      reader.readAsDataURL(file.raw);
      reader.onload = (e) => {
        submitFileList.push({
          image: e.target?.result,
        })
      }
    }

    const handleRemove = (file) => {
      if (file.id) {
        submitFileList.push({
          id: file.id,
          _destroy: 1,
        })
      }

      let resultArr = fileList.value.filter((i) => {
        return i.id === file.id && i.url === file.url;
      })

      fileList.value.splice(fileList.value.indexOf(resultArr[0]), 1);
    }

    const orderedProductGifts = () => {
      return _.orderBy(product.value.product_gifts_attributes, 'sorting', 'asc')
    }

    const move_up = (item) => {
      if (item.sorting > 0) {
        item.sorting -= 1
      }

      let sorting_items = _.orderBy(product.value.product_gifts_attributes, 'sorting', 'asc').filter((i) => {
        return i != item
      })

      for(var i = 0; i < sorting_items.length; i++) {
        if (i >= item.sorting) {
          sorting_items[i].sorting = i + 1
        } else {
          sorting_items[i].sorting = i
        }
      }
    }

    const move_down = (item) => {
      if (item.sorting < product.value.product_gifts_attributes.length - 1) {
        item.sorting += 1
      }

      let sorting_items = _.orderBy(product.value.product_gifts_attributes, 'sorting', 'asc').filter((i) => {
        return i != item
      })

      for(var i = 0; i < sorting_items.length; i++) {
        if (i >= item.sorting) {
          sorting_items[i].sorting = i + 1
        } else {
          sorting_items[i].sorting = i
        }
      }
    }

    const add_box_item_infos = (products) => {
      products.forEach((i) => {
        product.value.product_gifts_attributes.push({
          gift_product_id: i.id,
          brand: i.brand,
          product: i.name,
          price: i.price,
          recycle_price: i.recycle_price,
          quantity: 1,
          sorting: product.value.product_gifts_attributes.length
        } as ProductGift)
      })
    }

    const destroy_item = (item) => {
      item._destroy = 1
    }

    const avarage_price = () => {
      if (!(product.value && product.value.product_gifts_attributes)) {
        return 0
      }

      let total = 0
      let quantity = 0

      product.value.product_gifts_attributes.forEach((i) => {
        total += i.recycle_price * i.quantity
        quantity += i.quantity
      })

      if (quantity == 0) {
        return 0
      } else {
        return (total / quantity).toFixed(2)
      }
    }

    const sortByPrice = () => {
      let index = 0
      _.orderBy(product.value.product_gifts_attributes, 'recycle_price', 'desc').forEach((i) => {
        i['sorting'] = index
        index++
      })
    }

    const total_quantity = () => {
      if (!(product.value && product.value.product_gifts_attributes)) {
        return 0
      }

      let total = 0

      product.value.product_gifts_attributes.forEach((i: ProductGift) => {
        if (!i._destroy) {
          total += i.quantity
        }
      })

      return total
    }

    return {
      onSubmit,
      product,
      rules,
      onCancel,
      formRef,
      imageUrl,
      onChange,
      product_brand_options,
      product_ip_options,
      product_recycle_type_options,
      imageListOnChange,
      fileList,
      handleRemove,
      event_options,
      orderedProductGifts,
      move_up,
      move_down,
      add_box_item_infos,
      destroy_item,
      avarage_price,
      sortByPrice,
      total_quantity
    }
  }
});
